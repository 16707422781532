import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: (
        <span className={styles.description}>
            РћСЃС‚Р°РµМ€РјСЃСЏ Р·РёРјРѕРІР°С‚СЊ РёР»Рё РєР°Рє РїРѕС…РѕСЂРѕС€РµР»Рё РїС‚РёС†С‹ РїСЂРё РЎРѕР±СЏРЅРёРЅРµ
            <span className={styles.sold}>(РІ С‡Р°СЃС‚РЅРѕР№ РєРѕР»Р»РµРєС†РёРё)</span>
        </span>
    ),
    en: <span>Let's stay in winter or how birds have become better-looking under Sobyanin</span>,
};

export const LetsStayInWinter: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div>{description[locale]}</div>
        </div>
    );
};
