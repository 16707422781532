import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: (
        <span>
            "РЇ РЅРµ СЃРїР»СЋ РЅРѕС‡СЊСЋ
            <br /> РЇ РіСѓР»СЏСЋ РїРѕ РЅРѕС‡Р°Рј
            <br /> Р’ Р°С‚РјРѕСЃС„РµСЂРµ Р·РёРјР°
            <br /> РќРѕС‡СЊ РґР»РёРЅРЅР°СЏ"
        </span>
    ),
    en: <span>Who to sleep with</span>,
};

export const WhoToSleepWith: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div className={styles.description}>{description[locale]}</div>
        </div>
    );
};
