import { Page } from "../app.types";

export const SET_PAGE = "settings/SET_PAGE";

export interface SetPageAction {
    type: typeof SET_PAGE;
    payload: Page;
}

export const setPage = (payload: Page): SetPageAction => {
    return {
        type: SET_PAGE,
        payload,
    };
};
export type SettingsActions = SetPageAction;
