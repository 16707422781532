export enum Locale {
    Ru = "ru",
    En = "en",
}

export enum Page {
    Undefined = "",
    Design = "design",
    DesignProject = "design_project",
    Modeling = "modeling",
    OtherProjects = "other_projects",
    PrivacyPolicy = "privacy_policy",
}
