import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: (
        <span>
            "..РћС‚ РІРѕСЂРѕС‚ Рё РґРѕ РІРѕСЂРѕС‚
            <br />
            Р—РЅР°Р» РІ СЂР°Р№РѕРЅРµ РІРµСЃСЊ РЅР°СЂРѕРґ,
            <br />
            Р“РґРµ СЂР°Р±РѕС‚Р°РµС‚ РЎС‚РµРїР°РЅРѕРІ,
            <br /> Р§С‚Рѕ РѕРЅ РєСѓСЂРёС‚,
            <br /> РЎ РєРµРј РѕРЅ РїСЊРµС‚.."
        </span>
    ),
    en: <span>Stepanov</span>,
};

export const Stepanov: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div className={styles.description}>{description[locale]}</div>
        </div>
    );
};
