import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: <span>Р§РµС‚С‹СЂРµ Р»С‹Р¶РЅРёРєР° РђРїРѕРєР°Р»РёРїСЃРёСЃР° РёР»Рё Р”РёРєР°СЏ РѕС…РѕС‚Р° РЅР° РљР°СЂС…Р°СЂРѕРґРѕРЅР°</span>,
    en: <span>Four Skiers of the Apocalypse</span>,
};

export const FourSkiers: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div className={styles.description}>{description[locale]}</div>
        </div>
    );
};
