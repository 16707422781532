import { Link, Toolbar, Typography } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TelegramIcon from "@material-ui/icons/Telegram";
import React from "react";

import styles from "./GalleryHeader.module.scss";

const firstname = ".irene";
const domain = "cxp";
const mailservice = "gmail.";

export const GalleryHeader = () => {
    const onContacts = () => {
        const arr = ["mail", "to:", domain, firstname, "@", mailservice, "com", "?subject=from website"];
        window.location.href = arr.join("");
    };

    return (
        <Toolbar className={styles.toolbar}>
            <Typography className={styles.name} variant="h4">
                <span className={styles.author}>{"Рђ. Р§РёР¶"}</span>
            </Typography>
            <div className={styles.bubble} />
            <div className={styles.contacts}>
                <Link className={styles.email} onClick={onContacts}>
                    <MailOutlineIcon color="inherit" />
                </Link>
                <Link className={styles.telegram} href="https://t.me/OrlovaCxp" target="_blank">
                    <TelegramIcon color="inherit" />
                </Link>
            </div>
        </Toolbar>
    );
};
