import { Divider } from "@material-ui/core";
import React from "react";
import { Page } from "../store/app.types";

import { Footer } from "./Footer";
import { Header } from "./Header";
import styles from "./MainTemplate.module.scss";

export interface MainTemplateProps {
    page: Page;
}

export const MainTemplate: React.FC<MainTemplateProps> = ({ children, page }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Header page={page} />
                <Divider className={styles.divider} />
                {children}
                <Divider className={styles.divider} />
                <Footer />
            </div>
        </div>
    );
};
