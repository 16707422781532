import { Divider } from "@material-ui/core";
import React from "react";
import { Footer } from "../../components/Footer";
import { ScrollUpButton } from "../../components/ScrollUpButton";
import { GalleryHeader } from "./GalleryHeader";

import styles from "./GalleryPage.module.scss";
import { Assol } from "./СЃhizhevsky/assol/Assol";
import { BaskervilleAndStapleton } from "./СЃhizhevsky/baskerville-and-stapleton/BaskervilleAndStapleton";
import { CarrotForest } from "./СЃhizhevsky/carrot-forest/CarrotForest";
import { FishDay } from "./СЃhizhevsky/fish-day-on-arbatskaya/FishDay";
import { FourSkiers } from "./СЃhizhevsky/four-skiers-of-the-apocalypse/FourSkiers";
import { HuntersInTheSnow } from "./СЃhizhevsky/hunters-in-the-snow/HuntersInTheSnow";
import { ILoveAsphalt } from "./СЃhizhevsky/i-love-asphalt/ILoveAsphalt";
import { InBratsevo } from "./СЃhizhevsky/in-bratsevo/InBratsevo";
import { InThePark } from "./СЃhizhevsky/in-the-park/InThePark";
import { Khlebozavod } from "./СЃhizhevsky/khlebozavod/Khlebozavod";
import { KuznetskyMost } from "./СЃhizhevsky/kuznetsky-most/KuznetskyMost";
import { LetsStayInWinter } from "./СЃhizhevsky/lets-stay-in-winter/LetsStayInWinter";
import { Manezhnaya } from "./СЃhizhevsky/manezhnaya/Manezhnaya";
import { MayOnPatricks } from "./СЃhizhevsky/may-festivities-on-patricks/MayOnPatricks";
import { MushroomForest } from "./СЃhizhevsky/mushroom-forest/MushroomForest";
import { NoseOnFire } from "./СЃhizhevsky/nose-on-fire/NoseOnFire";
import { OnChistyePrudy } from "./СЃhizhevsky/on-chistye-prudy/OnChistyePrudy";
import { OnTheBorder } from "./СЃhizhevsky/on-the-border/OnTheBorder";
import { Pokrovka13 } from "./СЃhizhevsky/pokrovka-13/Pokrovka13";
import { PokrovskyBoulevard16 } from "./СЃhizhevsky/pokrovsky-boulevard-16/PokrovskyBoulevard16";
import { Preaching } from "./СЃhizhevsky/preaching/Preaching";
import { RushHour } from "./СЃhizhevsky/rush-hour/RushHour";
import { SholokhovMazai } from "./СЃhizhevsky/sholokhov-mazai/SholokhovMazai";
import { Skiers } from "./СЃhizhevsky/skiers/Skiers";
import { SmokeBreak } from "./СЃhizhevsky/smoke-break/SmokeBreak";
import { SnowAgain } from "./СЃhizhevsky/snow-again/SnowAgain";
import { Solyanka } from "./СЃhizhevsky/solyanka/Solyanka";
import { SpringForest } from "./СЃhizhevsky/spring-forest/SpringForest";
import { SpringIsComing } from "./СЃhizhevsky/spring-is-coming/SpringIsComing";
import { Stepanov } from "./СЃhizhevsky/stepanov/Stepanov";
import { Subway } from "./СЃhizhevsky/subway/Subway";
import { TheNutcracker } from "./СЃhizhevsky/the-nutcracker/TheNutcracker";
import { Exhibition } from "./СЃhizhevsky/to-the-exhibition/Exhibition";
import { VosstaniyaSquare } from "./СЃhizhevsky/vosstaniya-square/VosstaniyaSquare";
import { WhoToSleepWith } from "./СЃhizhevsky/who-to-sleep-with/WhoToSleepWith";
import { Zuev } from "./СЃhizhevsky/zuev/Zuev";

export const GalleryPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <GalleryHeader />
                <Divider className={styles.divider} />
                <div className={styles.items}>
                    <InThePark />
                    <LetsStayInWinter />
                    <OnTheBorder />
                    <Preaching />
                    <SnowAgain />
                    <RushHour />
                    <Assol />
                    <Pokrovka13 />
                    <PokrovskyBoulevard16 />
                    <BaskervilleAndStapleton />
                    <Zuev />
                    <Skiers />
                    <FishDay />
                    <FourSkiers />
                    <OnChistyePrudy />
                    <SmokeBreak />
                    <SpringForest />
                    <ILoveAsphalt />
                    <SpringIsComing />
                    <HuntersInTheSnow />
                    <MushroomForest />
                    <SholokhovMazai />
                    <MayOnPatricks />
                    <Solyanka />
                    <VosstaniyaSquare />
                    <Exhibition />
                    <InBratsevo />
                    <NoseOnFire />
                    <Manezhnaya />
                    <CarrotForest />
                    <Khlebozavod />
                    <KuznetskyMost />
                    <TheNutcracker />
                    <Subway />
                    <Stepanov />
                    <WhoToSleepWith />
                </div>
                <Divider className={styles.divider} />
                <Footer />
                <ScrollUpButton />
            </div>
        </div>
    );
};
