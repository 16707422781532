import { applyMiddleware, combineReducers, compose, createStore, Middleware, Store } from "redux";
import thunk from "redux-thunk";
import { SettingsActions } from "./settings/settings.actions";

import { settingsReducer } from "./settings/settings.reducer";
import { AppState } from "./app.state";

export type AppActions = SettingsActions;

export const createRootReducer = () =>
    combineReducers<AppState>({
        settings: settingsReducer,
    });

export function configureStore(initialState?: AppState): Store<AppState> {
    const middlewareList: Middleware[] = [thunk];

    const composeEnhancers =
        (process.env.NODE_ENV !== "production" &&
            typeof window === "object" &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    const store = createStore(createRootReducer(), initialState, composeEnhancers(applyMiddleware(...middlewareList)));

    if (process.env.NODE_ENV === "development" && (module as any).hot) {
        (module as any).hot.accept(createRootReducer(), () => {
            store.replaceReducer(createRootReducer());
        });
    }

    return store as any;
}
