import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: (
        <span>
            Р Р°Р±РѕС‡РёР№ РњРёСѓСЃСЃРєРѕРіРѕ С‚СЂР°РјРІР°Р№РЅРѕРіРѕ РїР°СЂРєР° РЎРµСЂРіРµР№ РњРёС…Р°Р№Р»РѕРІРёС‡ Р—СѓРµРІ СѓР±РёРІР°РµС‚ СѓРїСЂР°РІР»СЏСЋС‰РµРіРѕ РїР°СЂРєРѕРј Р·Р° РёР·РґРµРІР°С‚РµР»СЊСЃС‚РІР° Рё
            СѓРІРѕР»СЊРЅРµРЅРёРµ СЂРµРІРѕР»СЋС†РёРѕРЅРЅРѕ РЅР°СЃС‚СЂРѕРµРЅРЅС‹С… СЂР°Р±РѕС‡РёС…
        </span>
    ),
    en: <span>Zuev</span>,
};

export const Zuev: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div className={styles.description}>{description[locale]}</div>
        </div>
    );
};
