export const selectDesignRoute = (): string => "/design";
export const selectModelingRoute = (): string => "/modeling";
export const selectPrivacyPolicyRoute = (): string => "/privacy_policy";
export const selectGalleryRoute = (): string => "/gallery";

export const selectDefaultRoute = (): string => selectDesignRoute();

export const selectProjectRoute = (id: string) => {
    return selectDesignRoute() + "/" + id;
};
