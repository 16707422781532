import produce, { Draft } from "immer";

import { SettingsState } from "../app.state";

import { Locale, Page } from "../app.types";
import { SET_PAGE, SettingsActions } from "./settings.actions";

/**
 *
 * Initial state
 *
 */
export const initState: SettingsState = {
    locale: Locale.Ru,
    page: Page.Design,
};

/**
 *
 * Reducer
 *
 */
export const settingsReducer = produce((draft: Draft<SettingsState>, action: SettingsActions) => {
    switch (action.type) {
        case SET_PAGE:
            draft.page = action.payload;
            return;
    }
}, initState);
