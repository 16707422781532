import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: <span>РџР»РѕС‰Р°РґСЊ Р’РѕСЃСЃС‚Р°РЅРёСЏ. Р­С‚СЋРґ.</span>,
    en: <span>Vosstaniya Square</span>,
};

export const VosstaniyaSquare: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div className={styles.description}>{description[locale]}</div>
        </div>
    );
};
