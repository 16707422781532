import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/app.types";
import { selectLocale } from "../../../../store/settings/settings.selectors";
import styles from "../../item.module.scss";

import image from "./s.jpg";

const description: Record<Locale, React.ReactNode> = {
    ru: (
        <span>
            РќР°Рґ РµРґРѕР№ РЅРµ С‡Р°РІРєР°Р№, РєР°Рє СЃРІРёРЅСЊСЏ, Рё РіРѕР»РѕРІС‹ РЅРµ С‡РµС€Рё, РЅРµ РїСЂРѕРіР»РѕС‚СЏ РєСѓСЃРєР° РЅРµ РіРѕРІРѕСЂРё, РёР±Рѕ С‚Р°Рє РґРµР»Р°СЋС‚ РєСЂРµСЃС‚СЊСЏРЅРµ;
            С‡Р°СЃС‚Рѕ С‡РёС…Р°С‚СЊ, СЃРјРѕСЂРєР°С‚СЊ Рё РєР°С€Р»СЏС‚СЊ РЅРµРїСЂРёРіРѕР¶РµвЂ¦ РЅРµ Р·Р°РјР°СЂР°Р№ СЃРєР°С‚РµСЂС‚РёвЂ¦ РѕРєРѕР»Рѕ СЃРІРѕРµР№ С‚Р°СЂРµР»РєРё РЅРµ РґРµР»Р°Р№ Р·Р°Р±РѕСЂР° РёР·
            РєРѕСЃС‚РµР№, РєРѕСЂРѕРє С…Р»РµР±Р° Рё РїСЂРѕС‡РµРіРѕвЂ¦ вЂ“ РёР· СЂСѓРєРѕРІРѕРґСЃС‚РІР° В«Р®РЅРѕСЃС‚Рё С‡РµСЃС‚РЅРѕРµ Р·РµСЂС†Р°Р»Рѕ, РёР»Рё РџРѕРєР°Р·Р°РЅРёРµ Р¶РёС‚РµР№СЃРєРѕРјСѓ
            РѕР±С…РѕР¶РґРµРЅРёСЋВ», 1717.
        </span>
    ),
    en: <span>Nose on Fire</span>,
};

export const NoseOnFire: React.FC = () => {
    const locale = useSelector(selectLocale);

    return (
        <div className={styles.item}>
            <img className={styles.image} alt={""} src={image} />
            <div className={styles.description}>{description[locale]}</div>
        </div>
    );
};
